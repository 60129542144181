.marketing-list {
  width: 90%;
  min-height: calc(100vh - 150px);
  padding: 0 5%;

  margin-top: 50px;
  margin-bottom: 50px;
}

.marketing-list h2 {
  margin-top: 20px;
}

.marketing-list iframe {
  width: 100%;
  aspect-ratio: 16/9;
  border: none;
  margin-top: 20px;
}