  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 30px;
  }
  
  .Events {
    width: 100%;
    min-height: 100vh;
  }
  
  .EventsWrapper {
    display: flex;
    min-height: calc(100vh - 50px);
  }
  
  .EventsContent {
    width: 100%;
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    color: #333;
  }
  
  .mt-20 {
    margin-top: 20px;
  }
  
  .nodes-group-wrapper {
    display: flex;
  }
  
  .EventsPrice {
    display: flex;
    flex-direction: column;
    align-items: center;
  } 

  .events-content {
    width: calc(100vw - 280px);
    min-height: calc(100vh - 50px);
  }

  .events-content-title {
    width: 100%;
    text-align: center;
    margin: 50px 0px 25px 0px;
  }

  .events-content-title h1 small {
    font-size: 14px;
    color: #6a6a6a;
  }

  .tickets-sold {
    height: 200px;
    width: 200px;
  }

  .profit {
    height: 200px;
    width: 200px;
  }

  .profit-value {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 1.5em;
  }

  .cards-row {
    width: 90%;
    padding: 0 5%;
    display: flex;
    gap: 20px;
  }

  .events-list {
    width: 90%;
    min-height: calc(100vh - 150px);
    padding: 0 5%;

    margin-top: 50px;
    margin-bottom: 50px;
  }
  
  .card {
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 20px;
  }

  .sold {
    color: #0088FE;
  }

  .available {
    color: #00C49F;
  }

  .event-form {
    margin-top: 20px;
  }

  .event-form .form-group {
    width: 400px;
  }

  @media (max-width: 768px) {
    nav {
      display: none;
    }
  
    .EventsContent {
      font-size: 18px;
      text-align: center;
    }
  
    .EventsContent label {
      text-align: start;
    }

    .events-content {
      width: 100vw;
    }

    .cards-row {
        flex-direction: column;
    }

    .card {
        width: 90%;
    }
  }