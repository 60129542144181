.scanner {
  width: 100vw;
  height: 100dvh;

  display: flex;
  justify-content: center;
  align-items: center;
}

#video-container .scan-region-highlight-svg,
#video-container .scan-region-highlight {
  stroke: rgba(255, 255, 255, .5) !important;
  stroke-width: 10 !important;
  stroke-dasharray: none !important;
  position: relative;
}

#video-container .scan-region-highlight-svg::after,
#video-container .scan-region-highlight::after { 
  content: "";
  display: block; /* Ensure the pseudo-element is visible */
  width: 100%;
  height: 100%;
  position: absolute; /* Make sure it covers the parent element */
  top: 0;
  left: 0;
  background-image: url('../../../public/inverted.png');
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.1;
  pointer-events: none; /* Ensure it doesn’t interfere with interactions */
}