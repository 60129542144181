.floorplan-container {
    position: relative;
    height: 540px;
    width: 100%;
    border-radius: 5px;
  }
  
  .image-container {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    position: relative;
    display: inline-block;
    background: url('../../../public/lima_tables.jpg');
    transition: all 0.3s ease;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: rgb(84, 223, 233);
    background: radial-gradient(circle, rgba(84, 223, 233,0.85) 0%, rgba(84, 223, 233,0.75) 20%, rgba(84, 223, 233,0) 50%, rgba(84, 223, 233,0) 100%);
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .overlay:nth-of-type(1) {
    top: 0;
    left: 0;
  }

  .overlay:nth-of-type(2) {
    top: 0;
    left: 50%;
  }
  
  .overlay:hover {
    opacity: 1;
  }
  
  .overlay-image {
    display: block;
    width: 100%;
    height: auto;
  }
  