.table {
    position: absolute;

    width: 30px;
    height: 30px;

    background-color: #fff;
    color: #444;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    border: solid 4px #2290ef;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}

.popup {
    position: absolute;
    background-color: #fff;
    color: #333;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    top: 30px; /* Adjust the distance of the popup from the table */
    left: 0;
    z-index: 1;
    width: 250px;
}

.popup p {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 10px;
    margin-bottom: 10px;
}

.popup form {
    margin-left: 5%
}