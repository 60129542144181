.login {
  width: 100vw;
  height: 100vh;

  display: flex;
}

.login-left {
  margin: 5%;
  width: 30%;
  height: 90%;
}

.login-right {
  width: 60%;
  height: 100%;

  background-color: #151515;

  display: flex;
  justify-content: center;
  align-items: center;
}

.login-right svg {
  width: 50%;
}

.login-left form {
  margin-top: 125px;
}

@media (max-width: 768px) {
  .login-left {
    width: 90%;
  }

  .login-right {
    display: none;
  }
}