.dm {
    display: flex;
}

.dm-content {
    width: 100%;
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dm-content span {
    color: orange;
}

.dm-content h1 {
    color: white;
    font-size: 72px;
}

.dm-content button {
    cursor: pointer;
    border: none;
    height: 40px;
    border-radius: 3px;
    padding: 0 10px;
    color: white;
    background-color: orange;
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 24px;
    line-height: 1.8;
    margin-top: 50px;
    margin-bottom: 100px;
}