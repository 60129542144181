

.align-center {
    align-items: center;
}

.avatar > div {
    width: 30px !important;
    height: 30px !important;
    font-size: 0.9rem !important;
    margin-right: 10px;
}

.tag-popup {
    position: absolute;
    background-color: #fff;
    color: #333;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    top: 30px; /* Adjust the distance of the popup from the table */
    left: 0;
    z-index: 1;
    width: 100px;
    border-radius: 5px;
    padding: 10px;
    
    display: flex;
    flex-direction: column;
    gap: 10px;

    cursor: pointer;
}