.Footer {
    position: relative;
    bottom: 0;
    text-align: center;
    background-color: black;
    color: white;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .Footer p {
    font-size: 14px;
    opacity: 0.7;
  }