.finances-list {
  width: 90%;
  min-height: calc(100vh - 150px);
  padding: 0 5%;

  margin-top: 50px;
  margin-bottom: 50px;
}

.finances-list h2 {
  margin-top: 20px;
}