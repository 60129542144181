.register {
  width: 100vw;
  height: 100vh;

  display: flex;
}

.register-left {
  margin: 5%;
  width: 30%;
  height: 90%;
}

.register-right {
  width: 60%;
  height: 100%;

  background-color: #151515;

  display: flex;
  justify-content: center;
  align-items: center;
}

.register-right svg {
  width: 50%;
}

.register-left form {
  margin-top: 75px;
}

@media (max-width: 768px) {
  .register-left {
    width: 90%;
  }

  .register-right {
    display: none;
  }
}