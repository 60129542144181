

.align-center {
    align-items: center;
}

.avatar > div {
    width: 30px !important;
    height: 30px !important;
    font-size: 0.9rem !important;
    margin-right: 10px;
}