.tickets-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100vh;

  /* background: linear-gradient(to bottom, rgb(255, 255, 255), rgba(117, 19, 93, 0)),
  url("../../../public/poke2.jpeg");
  background-size: cover;
  background-position: center center; */
}

.tickets {
  width: 600px;

  border-radius: 5px;

  background-color: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;

  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.tickets small {
  color: #6a6a6a;
}

.terms {
  width: 600px;
  margin: 25px 0px;
  padding: 25px 0px;

  border-radius: 5px;

  background-color: #fff;

  display: flex;
  align-items: center;

  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.info {
  width: 600px;
  margin-top: 25px;
  padding: 25px 0px;

  border-radius: 5px;

  background-color: #fff;

  display: flex;
  align-items: center;

  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.payments {
  width: 600px;
  margin: 25px 0px;

  border-radius: 5px;

  background-color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.payments img {
  width: 600px;
  filter: blur(1px);
}

.terms span {
  color: rgb(64, 64, 241);
  cursor: pointer;
}

.terms input {
  margin-left: 5%;
  margin-right: 10px;
}

.info form {
  margin-left: 5%;
  margin-right: 5%;

  width: 100%;
}

.tickets h1 {
  padding: 10px 0px;
  width: 100%;
  text-align: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  font-size: 24px;
}

.tickets h1 small {
  font-size: 14px;
}

.tickets hr {
  height: 2px;
  background-color: rgb(236 243 250);
  width: 100%;
  border: none;
}

.ticket-price {
  display: flex;
  flex-direction: column;
}

.ticket-price img {
  height: 14px;
}

.ticket-amount {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 50px;
}

.ticket-amount button {
  border: none;
  background: none;
}

.total {
  width: 600px;
  padding: 25px 0px;

  border-radius: 5px;

  background-color: #fff;

  display: flex;
  align-items: center;
  justify-content: space-between;

  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.total small {
  color: #6a6a6a;
}

.total .ticket-price {
  margin-left: 5%;
}

.total-pay {
  margin-right: 5%;
}

.total-pay button {
  border: none;
	background:linear-gradient(to bottom, #f546c1 5%, #922abf 100%);
	background-color:#f546c1;
	border-radius:28px;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:14px;
	font-weight:bold;
	padding:10px 15px;
	text-decoration:none;
  transition: all 1s ease-in-out;
}

.total-pay button:hover {
	background:linear-gradient(to bottom, #922abf 5%, #f546c1 100%);
	background-color:#922abf;
}

.total-pay button:active {
	position:relative;
	top:1px;
}

.form-group-duo {
  display: flex;
  justify-content: space-between;
}

.form-group-duo .form-group {
  width: 45%;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.form-group input, .form-group select {
  border: 1px solid #c2c2c2;
  border-radius: 5px;
  height: 20px;
  padding: 0px 4px;
}

.form-group label {
  font-size: 16px;
  margin: 5px 0px;
  color: #504e4e;
}

.ticket-qr {
  margin: 50px 0px;
}